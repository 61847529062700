<template>
  <div :class="[b(),className]"
       :style="styleSizeName">
    <div :ref="id"
         :style="styleChartName"></div>
  </div>
</template>

<script>
import create from "../../create";

export default create({
  name: "bar",
  data() {
    return {
      carouselCheck: "",
      carouselCount: 0,
    }
  },
  watch: {
    carousel() {
      this.setCarousel();
    },
    dataChartLen() {
      this.setCarousel();
    },
    carouselTime() {
      this.setCarousel();
    },
    carousel: {
      handler() {
        this.setCarousel();
      },
      immediate: true
    },
  },
  computed: {
    carouselTime() {
      return this.option.carouselTime || 3000;
    },
    carousel() {
      return this.vaildData(this.option.carousel, false);
    },
  },
  methods: {
    resetCarousel() {
      this.$nextTick(() => {
        this.myChart.dispatchAction({
          type: "hideTip"
        });
      });
    },
    setCarousel() {
      clearInterval(this.carouselCheck);
      if (this.carousel) {
        const carouselData = this.dataChart.categories || [];

        if (carouselData.length > 0) {
          this.carouselCheck = setInterval(() => {
          const curr = this.carouselCount % carouselData.length;
          this.myChart.dispatchAction({
            type: "showTip",
            seriesIndex: "0",
            dataIndex: curr
          });
          this.myChart.dispatchAction({
            type: "downplay"
          });
          this.myChart.dispatchAction({
            type: "highlight",
            dataIndex: curr
          });
          this.carouselCount += 1;
        }, this.carouselTime);
        }
      }
    },
    updateChart () {
      const optionData = this.deepClone(this.dataChart);
      const option = {
        title: this.getOptionTitle(),
        tooltip: this.getOptionTip({
          trigger: "axis",
        }),
        grid: this.getOptionGrid(),
        legend: this.getOptionLegend(),
        xAxis: {
          show: this.vaildData(this.option.xAxisShow, true),
          type: this.option.category ? "value" : "category",
          name: this.option.xAxisName,
          nameTextStyle: {
            color: this.option.xAxisColor || "#fff",
            fontSize: this.option.xAxisFontSize || 14
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: this.option.xAxisLineColor || "#fff"
            }
          },
          data: optionData.categories || [],
          inverse: this.vaildData(this.option.xAxisInverse, false),
          splitLine: {
            show: this.vaildData(this.option.xAxisSplitLineShow, false),
            lineStyle: {
              type: this.option.xAxisSplitLineType || "dashed",
              color: this.option.xAxisSplitLineColor || "#ffffff40"
            }
          },
          axisLabel: {
            interval: this.option.xAxisInterval || 'auto',
            rotate: this.option.xAxisRotate || 0,
            textStyle: {
              color: this.option.xAxisColor || "#fff",
              fontSize: this.option.xAxisFontSize || 14
            }
          },
          ...optionData.xAxis,
        },
        yAxis: {
          show: this.vaildData(this.option.yAxisShow, true),
          type: this.option.category ? "category" : "value",
          name: this.option.yAxisName,
          nameTextStyle: {
            color: this.option.yAxisColor || "#fff",
            fontSize: this.option.yAxisFontSize || 14
          },
          min: optionData.yAxis? (optionData.yAxis.min ? optionData.yAxis.min:(this.option.yAxisMini ||0)) : (this.option.yAxisMini ||0),
          data: optionData.categories || [],
          axisLabel: {
            textStyle: {
              color: this.option.yAxisColor || "#fff",
              fontSize: this.option.yAxisFontSize || 14
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: this.option.yAxisLineColor || "#fff"
            }
          },
          inverse: this.vaildData(this.option.yAxisInverse, false),
          splitLine: {
            show: this.vaildData(this.option.yAxisSplitLineShow, false),
            lineStyle: {
              type: this.option.yAxisSplitLineType || "dashed",
              color: this.option.yAxisSplitLineColor || "#ffffff40"
          }
          },
          ...optionData.yAxis,
        },
        series: (() => {
          const barColor = this.option.barColor || [];
          const list = (optionData.series || []).map((ele, index) => {
            return Object.assign(ele, {
              type: "bar",
              stack: ele.stack,
              barWidth: this.option.barWidth || 16,
              barMinHeight: this.option.barMinHeight || 0,
              itemStyle: this.getHasProp(!this.switchTheme, {
                // color: this.getColor(index)
                color: optionData.color ? optionData.color[index] : this.getColor(index)
              }, { barBorderRadius: this.option.barRadius || 0 }),
              label: this.getOptionLabel({
                position: this.option.category ? "right" : "top",
              })
            });
          });
          return list;
        })()
      };
      
      this.myChart.off("mouseover");
      this.myChart.off("mouseout");

      this.myChart.on("mouseover", () => {
        clearInterval(this.carouselCheck);
        this.resetCarousel();
      });
      this.myChart.on("mouseout", () => {
        this.carouselCount = 0;
        this.setCarousel();
      });

      this.myChart.resize();
      this.myChart.setOption(option, this.initialize);
    }
  }
});

</script>
