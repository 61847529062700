<template>
  <div :class="[b(),className]"
       :style="styleSizeName">
    <div :class="b('title')"
         v-html="titleFormatter && titleFormatter(dataChart)"></div>
    <div :ref="id"
         :style="styleChartName"></div>
  </div>
</template>

<script>
import create from "../../create";
export default create({
  name: "pie",
  data() {
    return {
      carouselCheck: "",
      carouselCount: 0,
    }
  },
  watch: {
    carousel() {
      this.setCarousel();
    },
    dataChartLen() {
      this.setCarousel();
    },
    carouselTime() {
      this.setCarousel();
    },
    carousel: {
      handler() {
        this.setCarousel();
      },
      immediate: true
    },
  },
  computed: {
    carouselTime() {
      return this.option.carouselTime || 3000;
    },
    carousel() {
      return this.vaildData(this.option.carousel, false);
    },
  },
  methods: {
    resetCarousel() {
      this.$nextTick(() => {
        this.myChart.dispatchAction({
          type: "hideTip"
        });
      });
    },
    setCarousel() {
      clearInterval(this.carouselCheck);
      if (this.carousel) {
        this.carouselCheck = setInterval(() => {
          const curr = this.carouselCount % this.dataChartLen;
          this.myChart.dispatchAction({
            type: "showTip",
            seriesIndex: "0",
            dataIndex: curr
          });
          this.myChart.dispatchAction({
            type: "downplay"
          });
          this.myChart.dispatchAction({
            type: "highlight",
            dataIndex: curr
          });
          this.carouselCount += 1;
        }, this.carouselTime);
      }
    },
    updateChart() {
      const optionData = this.deepClone(this.dataChart) || [];
      const option = {
        title: this.getOptionTitle(),
        tooltip: this.getOptionTip(),
        grid: this.getOptionGrid(),
        legend: this.getOptionLegend(),
        series: (() => {
          const barColor = this.option.barColor || [];
          const list = [
            {
              type: "pie",
              roseType: this.option.roseType ? "radius" : "",
              radius: this.option.radius ? ["40%", "55%"] : "50%",
              center: ["50%", "60%"],
              animationType: "scale",
              animationEasing: "elasticOut",
              animationDelay: function (idx) {
                return Math.random() * 200;
              },
              label: this.getOptionLabel({
                formatter: '{b}:{c}\n{d}%'
              }),
              data: (() => {
                let list = optionData;
                if (this.option.notCount) {
                  list = list.filter(ele => ele.value !== 0 && ele.value);
                }
                if (this.option.sort) {
                  list.sort((a, b) => a.value - b.value);
                }
                return list;
              })(),
              itemStyle: this.getHasProp(!this.switchTheme, {
                color: params => this.getColor(params.dataIndex)
              }, {
                emphasis: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)"
                }
              }),
            }
          ];
          return list;
        })()
      };

      this.myChart.off("mouseover");
      this.myChart.off("mouseout");

      this.myChart.on("mouseover", () => {
        clearInterval(this.carouselCheck);
        this.resetCarousel();
      });
      this.myChart.on("mouseout", () => {
        this.carouselCount = 0;
        this.setCarousel();
      });

      this.myChart.resize();
      this.myChart.setOption(option, this.initialize);
    }
  }
});
</script>
